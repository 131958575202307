

import { defineComponent } from 'vue'
import NewsletterPopup from '@/components/NewsletterPopup.vue'



export default defineComponent({
    name: "AppointmentForm",
    components:
    {
        NewsletterPopup
    },
    data(){
        return{
            editModeOn: false,
            sectionTitle: 'Sacá Turno',
            sectionText: '',
            changeSection: false,
            fullname: '',
            coupon: '',
            form: {
                first_name: '',
                last_name: '',
                email: '',
                phone: '',
                exp_date: '',
                id: '',
            },
            showNewsletterPopup: false
        }
    },
    mounted(){
        //check auth and get token
        // let checkAuth = utilities.checkAuth('mi-cuenta') as any
        // Promise.resolve(checkAuth).then((data: any[any]) => {
        //     this.form.first_name = data.data.first_name;
        //     this.form.last_name = data.data.last_name;
        //     this.form.email = data.data.email;
        // })
        this.showNewsletterPopup = true

        
    },
    computed:{
        whatsappText(){
          let text = this.coupon == '' ? 'Hola! Quisiera reservar un turno' : 'Hola! Tengo código de descuento para CONSULTA+REPROCARD ' + this.coupon
          let url = 'https://api.whatsapp.com/send?phone=5491153501666&text='+text;
          return url;
        }
    },

})

